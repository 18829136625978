import Logo from '../../assets/images/logo.svg'
import styled, { useTheme } from 'styled-components/macro'
import '../../assets/css/footer.css'
import { useState, useEffect } from 'react'


export function BrandFooter() {

  const [coinPrice, setCoinPrice] = useState('0.00000000000');

  function toFixed(x: any) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }

  const getCoinPairDataFooter = async () => {
    let requestOptions: any = {
      method: 'GET',
      redirect: 'follow'
    };

    await fetch("https://pricemonica.com/openapi/dex/pairinfo?pair=0x0dba3dfee43d9b6450c716c58fdae8d3be37fdc9&chain=ether", requestOptions)
      .then(async response => await response.json())
      .then(result => {
        const coinPriceToNumber = Number(result.data[0].price).toFixed(12)
        setCoinPrice(coinPriceToNumber);
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getCoinPairDataFooter();
  },[coinPrice])
  return (
    <footer className="page-footer">
      <div className="page-footer__wrapper">
        <a className="page-footer__logo">
          <img src={Logo} />
        </a>
        <div className="page-footer__buttons">
          <button className="page-footer__button" disabled>
            <img src={Logo} />
            ${coinPrice}
          </button>

        </div>
        <div className="page-footer__links">
          <nav className="page-footer__nav">
            <a href='#'>Home</a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x0dba3dfee43d9b6450c716c58fdae8d3be37fdc9' target='_blank'>Chart</a>
            <a href='https://pawburn.io' target='_blank'>Burn</a>
          </nav>
          <nav className="page-footer__nav">
            <a href='https://twitter.com/pawchain' target='_blank'>Twitter</a>
            <a href='http://t.me/Paw_Community_Owned' target='_blank'>Telegram</a>
            <a href='https://medium.com/@0xpawswap' target='_blank'>Medium</a>
          </nav>
        </div>
      </div>
    </footer>
  )
}
